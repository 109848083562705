import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
export const frontmatter = {
  title: "Gen Z's Pragmatic Impact On Innovation",
  date: "2018-08-08",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20180808-0.jpg",
  path: "2018-08-08-Gen-Z-Impact",
  teaserText: "While editors have fawned over Millennials, it is Generation Z, the 70 million plus Americans born between the late '90s and early aughts, which will rock many an industry and company."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="Gen Z's Pragmatic Impact On Innovation" description="While editors have fawned over Millennials, it is Generation Z, the 70 million plus Americans born between the late '90s and early aughts, which will rock many an industry and company." author='Roberto Ramos' keywords={['Robots']} />
      <ImageLandscape imageSrc="20180808-1.jpg" />
      <MDXTag name="p" components={components}>{`We are a product of our times. Each generation has its markers; those shared moments that shape how we see the world and guide our actions. For instance, Boomers ushered in counter-culture as a response to social and political uncertainties, while Millennials, the first digitally native generation, challenge all forms of clunky intermediaries. These societal milestones are the tipping points for change. And speaking of change, a big one is brewing.`}</MDXTag>
      <ImageLandscape imageSrc="20180808-2.jpg" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Z is For Huge`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`While editors have fawned over Millennials, it is Generation Z, the 70 million plus Americans born between the late '90s and early aughts, which will rock many an industry and company. Raised amidst the Great Recession while witnessing the increasingly isolating power of technology, Generation Z embodies a skeptical and pragmatic take on the world. They've witnessed first hand what the power structures and shifts of the '90s did to their Generation X parents (unemployment, debt levels, dissatisfaction) and have chosen a different route.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`The Pragmatic Economy`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`As Generation Z bubbles up to become the biggest consumer segment, their sense of pragmatism will have a powerful impact on creative, business and engagement models. We predict their need to make more with less will further challenge the concept of ownership, fuel the sharing economy, and put greater pressures on brands to deliver relevant, multi-faceted experiences while serving up trust and loyalty to a greater degree.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Ways In Which We're Seeing Gen Z Pragmatism Rise:`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`More so than Millennials, Generation Z is keen on savings. This will put greater pressure on brands and companies to pack more value around the goods and services they provide. This also presents an opportunity for financial services companies to speak to this consumer earlier. Other opportunities include: next gen loyalty programs, innovations in payment models, and continued novelties in business models such as subscription services.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Around the premise of value, they will demand more depth of meaning and functionality from the products and brands they endorse. Think of multi-use products that incorporate elements such as sustainability, weather adaptive uses, genderless offerings, among other qualities that will allow reuse, sharing, barter, etc.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Afraid of uncertainty, Generation Z is more openly loyal to companies and brands they perceive as authentically caring and relevant. In return for this loyalty, they will demand transparency, flexibility and a total life form of experience.`}</MDXTag>
      <ImageLandscape imageSrc="20180808-3.jpg" />
      <MDXTag name="p" components={components}>{`This is the generation that grew up with YouTube stars and other influencers. They do not subscribe to the traditional Hollywood model, and as such they will further force companies to build a more authentic network of influencers to spread the word about their brands and services. This generation also takes its cue from micro-influencers to craft their own versions of DIY expressions, taking full advantage of a new spate of digital creative tools.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Barter and cash-me will be a way of life. The search for authenticity, uniqueness and collaboration coupled with the need to save will continue to drive business models such as Poshmark, Grailed and its reselling of sneakers, and other direct-to-consumer exchanges, not to mention, informal peer-to-peer, community-driven barter.`}</MDXTag>
      <ImageLandscape imageSrc="20180808-4.jpg" />
      <MDXTag name="p" components={components}>{`In response to witnessing the uncertainties their parents faced, they will put a greater premium on smaller, more meaningful and trustworthy circles of friends and colleagues. Shared values, causes and collaborations will fuel this sense of agency and community. Opportunities: passion tribes and circles, next generation loyalty programs; dialing up security; transparency and engagement around blockchain.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Across the board, Generation Z presents a needed realness test for brands. This will mean a scrappier, creatively intuitive approach that reflects the harder times they grew up in. This could be a new boon for creativity, as the saying beckons, necessity is the mother of invention.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    